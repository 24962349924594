import { slideDown, slideUp } from '../../base/js/core'

const options = () => {
	const arrCheckbox = document.querySelectorAll('.options__checkbox')
	const variantsSlideToggle = (checkbox, varitants) => {
		if (checkbox.checked) {
			slideDown(varitants)
		} else {
			slideUp(varitants)
		}
	}
	const pointToggle = (checkbox) => {
		let point = document.getElementById(checkbox.dataset.point)
		if (point && checkbox.checked) {
			point.classList.add('is-active')
		} else if (point) {
			point.classList.remove('is-active')
		}
	}
	arrCheckbox.forEach(checkbox => {
		let varinants = checkbox.nextElementSibling.querySelector('.js-dropdown')
		pointToggle(checkbox)
		if (varinants) {
			variantsSlideToggle(checkbox, varinants)
		}
		checkbox.addEventListener('change', () => {
			pointToggle(checkbox)
			if (varinants) {
				variantsSlideToggle(checkbox, varinants)
			}
		})
	})
}
options()
