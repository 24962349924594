const arrPageHead = document.querySelectorAll('.page-head')

document.addEventListener('DOMContentLoaded', () => {
	arrPageHead.forEach(head => {
		let content = head.dataset.txt
		let tmp = document.createElement('div')
		tmp.textContent = content
		tmp.classList.add('page-head__txt-tmp')
		head.prepend(tmp)
		let currentSize = parseInt(window.getComputedStyle(tmp).fontSize, 10)
		let tmpW = tmp.offsetWidth
		let titleH = parseInt(window.getComputedStyle(head.querySelector('.page-head__title')).lineHeight, 10)
		let headW = head.offsetWidth
		const fontSize = () => {
			if (tmpW < headW) {
				currentSize++
				tmpW = tmp.offsetWidth
				tmp.style.fontSize = `${currentSize}px`
				fontSize()
			} else {
				if (tmp.offsetHeight > titleH) {
					head.style.paddingTop = `${tmp.offsetHeight - titleH}px`
				} else {
					head.style.paddingTop = `${tmp.offsetHeight}px`
				}

				tmp.style.fontSize = `${currentSize - 1}px`
			}
		}
		fontSize()
		window.addEventListener('resize', () => {
			tmp.removeAttribute('style')
			tmpW = tmp.offsetWidth
			headW = head.offsetWidth
			head.removeAttribute('style')
			titleH = parseInt(window.getComputedStyle(head.querySelector('.page-head__title')).lineHeight, 10)
			currentSize = parseInt(window.getComputedStyle(tmp).fontSize, 10)
			fontSize()
		}, true)
	})
})
