const arrPopup = document.querySelectorAll('[data-popup]')
// scrollBarWidth
const getScrollbarWidth = () => {
	const outer = document.createElement('div')
	outer.style.visibility = 'hidden'
	outer.style.overflow = 'scroll'
	outer.style.msOverflowStyle = 'scrollbar'
	document.body.appendChild(outer)
	const inner = document.createElement('div')
	outer.appendChild(inner)
	const scrollbarWidth = outer.offsetWidth - inner.offsetWidth
	outer.parentNode.removeChild(outer)

	return scrollbarWidth
}
const arrClose = document.querySelectorAll('.js-popup-close')
arrClose.forEach(btn => {
	btn.addEventListener('click', e => {
		e.preventDefault()
		document.documentElement.removeAttribute('style')
		document.documentElement.classList.remove('is-popup-open')
		btn.closest('.popup').classList.remove('is-open')
		btn.closest('.popup').scrollTop = 0
	})
})
arrPopup.forEach(btn => {
	let popup = document.getElementById(btn.dataset.popup.slice(1))
	btn.addEventListener('click', e => {
		e.preventDefault()
		document.documentElement.style.paddingRight = `${getScrollbarWidth()}px`
		document.documentElement.classList.add('is-popup-open')
		popup.classList.add('is-open')
	})
})
document.addEventListener('click', e => {
	if (!e.target.closest('.popup__in')
		&& document.documentElement.classList.contains('is-popup-open')
		&& !e.target.closest('[data-popup]')
	) {
		document.querySelector('.popup.is-open').scrollTop = 0
		document.documentElement.removeAttribute('style')
		document.documentElement.classList.remove('is-popup-open')
		document.querySelector('.popup.is-open').classList.remove('is-open')
	}
})
