export const breakpoints = {
	phone: 0,
	tablet: 768,
	desktop: 992
}

export let slideUp = (target, duration = 500, callback) => {
	target.style.transitionProperty = 'height, margin, padding'
	target.style.transitionDuration = `${duration}ms`
	target.style.boxSizing = 'border-box'
	target.style.height = `${target.offsetHeight}px`
	// eslint-disable-next-line no-unused-expressions
	target.offsetHeight
	target.style.overflow = 'hidden'
	target.style.height = 0
	target.style.paddingTop = 0
	target.style.paddingBottom = 0
	target.style.marginTop = 0
	target.style.marginBottom = 0
	window.setTimeout(() => {
		target.style.display = 'none'
		target.style.removeProperty('height')
		target.style.removeProperty('padding-top')
		target.style.removeProperty('padding-bottom')
		target.style.removeProperty('margin-top')
		target.style.removeProperty('margin-bottom')
		target.style.removeProperty('overflow')
		target.style.removeProperty('transition-duration')
		target.style.removeProperty('transition-property')
		if (callback) {
			callback()
		}
	}, duration)
}

export let slideDown = (target, duration = 500, callback) => {
	target.style.removeProperty('display')
	let display = window.getComputedStyle(target).display

	if (display === 'none') {
		display = 'block'
	}
	target.style.display = display
	let height = target.offsetHeight
	target.style.overflow = 'hidden'
	target.style.height = 0
	target.style.paddingTop = 0
	target.style.paddingBottom = 0
	target.style.marginTop = 0
	target.style.marginBottom = 0
	// eslint-disable-next-line no-unused-expressions
	target.offsetHeight
	target.style.boxSizing = 'border-box'
	target.style.transitionProperty = 'height, margin, padding'
	target.style.transitionDuration = `${duration}ms`
	target.style.height = `${height}px`
	target.style.removeProperty('padding-top')
	target.style.removeProperty('padding-bottom')
	target.style.removeProperty('margin-top')
	target.style.removeProperty('margin-bottom')
	window.setTimeout(() => {
		target.style.removeProperty('height')
		target.style.removeProperty('overflow')
		target.style.removeProperty('transition-duration')
		target.style.removeProperty('transition-property')
		if (callback) {
			callback()
		}
	}, duration)
}

export let slideToggle = (target, duration = 500, callback) => {
	let display = window.getComputedStyle(target).display
	if (display === 'none') {
		slideDown(target, duration, callback)
	} else {
		slideUp(target, duration, callback)
	}
}
const getChildren = (n, skipMe) => {
	let r = []
	for (; n; n = n.nextSibling) {
		if (n.nodeType === 1 && n !== skipMe) {
			r.push(n)
		}
	}

	return r
}

export const validate = (selector) => {
	const inputsValidate = document.querySelectorAll(selector)
	inputsValidate.forEach($input => {
		$input.addEventListener('invalid', (e) => {
			e.preventDefault()
			$input.classList.add('is-invalid')
			$input.closest('.input').classList.add('is-invalid')
		})
		$input.addEventListener('input', () => {
			$input.classList.remove('is-invalid')
			$input.closest('.input').classList.remove('is-invalid')
			if ($input.getAttribute('type') === 'radio') {
				const radios = $input.closest('form').querySelectorAll(`input[name="${$input.getAttribute('name')}"]`)
				radios.forEach($radio => {
					$radio.classList.remove('is-invalid')
				})
			}
		})
	})
}
validate('.js-validate [required]')

const tabsSlideToggle = (selector) => {
	const buttons = document.querySelectorAll(selector)
	buttons.forEach($button => {
		const $tab = document.getElementById($button.dataset.tabToggle.substr(1))
		$button.addEventListener('click', (e) => {
			e.preventDefault()
			$button.classList.toggle('is-active')
			slideToggle($tab)
		})
	})
}
tabsSlideToggle('[data-tab-toggle]')

import inputmask from 'inputmask'
const $inputMask = document.querySelectorAll('[data-mask]')
$inputMask.forEach($input => {
	const mask = $input.dataset.mask
	inputmask({
		mask,
		showMaskOnHover: false
	}).mask($input)
})
class customSelect {
	constructor(selector) {
		const arrSelect = document.querySelectorAll(selector)
		arrSelect.forEach(select => {
			select.style.display = 'none'
			this.create(select)
		})
		document.addEventListener('click', e => {
			if (!e.target.closest('.custom-select')) {
				let arrSelectOpen = document.querySelectorAll('.custom-select.is-open')
				arrSelectOpen.forEach(select => {
					this.close(select, select.querySelector('.custom-select__dropdown'))
				})
			}
		})
	}

	create(select) {
		let arrOptions = select.querySelectorAll('option')
		let cSelect = document.createElement('div')
		cSelect.classList.add('custom-select')
		let cSelectHead = document.createElement('div')
		cSelectHead.classList.add('custom-select__head')
		let cSelectTtl = document.createElement('div')
		cSelectTtl.classList.add('custom-select__ttl')
		let ttl = select.dataset.placeholder ? select.dataset.placeholder : arrOptions[0].textContent
		if (select.dataset.placeholder) {
			cSelectTtl.classList.add('custom-select__ttl_has-placeholder')
		}
		cSelectTtl.textContent = ttl
		let arrow = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
		arrow.classList.add('custom-select__arrow')
		arrow.setAttribute('width', '16')
		arrow.setAttribute('height', '10')
		arrow.setAttribute('viewBox', '0 0 16 10')
		let path = document.createElementNS('http://www.w3.org/2000/svg', 'path')
		path.setAttribute(
			'd',
			'M15.7382 0.954125C15.9334 1.14939 15.9334 1.46597 15.7382 1.66123L8.35355 9.04585C8.15829 9.24111 7.84171 9.24111 7.64644 9.04585L0.261829 1.66123C0.0665674 1.46597 0.0665674 1.14939 0.261829 0.954124C0.457092 0.758862 0.773674 0.758862 0.968937 0.954124L8 7.98519L15.0311 0.954125C15.2263 0.758863 15.5429 0.758863 15.7382 0.954125Z'
		)
		let clear = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
		clear.classList.add('custom-select__clear')
		clear.setAttribute('width', '16')
		clear.setAttribute('height', '16')
		clear.setAttribute('viewBox', '0 0 16 16')
		let clearPath = document.createElementNS('http://www.w3.org/2000/svg', 'path')
		let clearPath2 = document.createElementNS('http://www.w3.org/2000/svg', 'path')
		clear.addEventListener('click', () => {
			select.selectedIndex = 0
			cSelectTtl.textContent = ttl
			if (select.dataset.placeholder) {
				cSelectTtl.classList.add('custom-select__ttl_has-placeholder')
			}
			cSelect.classList.remove('is-change')
			let event = new Event('change')
			select.dispatchEvent(event)
		})
		clearPath.setAttribute(
			'd',
			'M0.26183 0.261834C0.457092 0.0665714 0.773674 0.0665714 0.968937 0.261834L15.7382 15.0311C15.9334 15.2263 15.9334 15.5429 15.7382 15.7382C15.5429 15.9334 15.2263 15.9334 15.0311 15.7382L0.26183 0.96894C0.0665676 0.773678 0.0665676 0.457096 0.26183 0.261834Z'
		)
		clearPath2.setAttribute(
			'd',
			'M15.7382 0.261834C15.9334 0.457096 15.9334 0.773678 15.7382 0.96894L0.968937 15.7382C0.773674 15.9334 0.457092 15.9334 0.26183 15.7382C0.0665676 15.5429 0.0665676 15.2263 0.26183 15.0311L15.0311 0.261834C15.2263 0.0665714 15.5429 0.0665714 15.7382 0.261834Z'
		)
		clear.appendChild(clearPath)
		clear.appendChild(clearPath2)
		let cSelectDropdown = document.createElement('div')
		cSelectDropdown.classList.add('custom-select__dropdown')
		arrOptions.forEach((option, index) => {
			if (option.textContent === '') {
				return
			}
			let cSelectOption = document.createElement('div')
			cSelectOption.classList.add('custom-select__itm')
			cSelectOption.textContent = option.textContent
			if (option.dataset.image) {
				let image = document.createElement('img')
				image.classList.add('custom-select__img')
				image.setAttribute('src', option.dataset.image)
				cSelectOption.prepend(image)
			}
			if (option.selected && option.textContent !== '') {
				cSelectTtl.classList.remove('custom-select__ttl_has-placeholder')
				cSelect.classList.add('is-change')
				cSelectTtl.textContent = option.textContent
			}
			cSelectOption.addEventListener('click', () => {
				select.selectedIndex = index
				cSelect.classList.add('is-change')
				cSelectTtl.classList.remove('custom-select__ttl_has-placeholder')
				cSelectTtl.textContent = option.textContent
				if (cSelectDropdown.querySelector('.is-selected')) {
					cSelectDropdown.querySelector('.is-selected').classList.remove('is-selected')
				}
				cSelectOption.classList.add('is-selected')
				this.close(cSelect, cSelectDropdown)
				let event = new Event('change')
				select.dispatchEvent(event)
			})
			cSelectDropdown.appendChild(cSelectOption)
		})
		arrow.appendChild(path)
		cSelectHead.appendChild(clear)
		cSelectHead.appendChild(arrow)
		cSelectHead.prepend(cSelectTtl)
		cSelect.appendChild(cSelectHead)
		cSelect.appendChild(cSelectDropdown)
		select.after(cSelect)
		cSelectHead.addEventListener('click', e => {
			if (!e.target.closest('.custom-select__clear')) {
				this.open(cSelect, cSelectDropdown)
			}
		})
	}

	close(select, dropdown) {
		select.classList.remove('is-open')
		slideUp(dropdown)
	}

	open(select, dropdown) {
		if (select.classList.contains('is-open')) {
			this.close(select, dropdown)
		} else {
			let arrSelectOpen = document.querySelectorAll('.custom-select.is-open')
			arrSelectOpen.forEach(selectOpen => {
				this.close(selectOpen, selectOpen.querySelector('.custom-select__dropdown'))
			})
			select.classList.add('is-open')
			slideDown(dropdown)
		}
	}
}

new customSelect('.js-select')
import Swiper, { Navigation } from 'swiper'
const arrCarousel = document.querySelectorAll('.carousel')
arrCarousel.forEach(block => {
	let carousel = block.querySelector('.swiper')
	let nextEl = block.querySelector('.js-next')
	let prevEl = block.querySelector('.js-prev')
	// eslint-disable-next-line no-unused-vars
	let swiper = new Swiper(carousel, {
		modules: [Navigation],
		loop: true,
		slidesPerView: 1,
		spaceBetween: 10,
		navigation: {
			nextEl,
			prevEl
		},
		breakpoints: {
			320: {
				slidesPerView: 1,
				spaceBetween: 0
			},
			768: {
				slidesPerView: 3,
				spaceBetween: 20
			},
			992: {
				slidesPerView: 3,
				spaceBetween: 20
			}
		}
	})
})
const counter = () => {
	const btnDisabled = (input) => {
		let minus = input.closest('.counter').querySelector('.counter__btn_minus')
		let plus = input.closest('.counter').querySelector('.counter__btn_plus')
		let max = parseInt(input.getAttribute('max'), 10)
		let min = parseInt(input.getAttribute('min'), 10) || 1
		let value = parseInt(input.value, 10)
		if (max && value === max) {
			plus.setAttribute('disabled', 'disabled')
		} else {
			plus.removeAttribute('disabled')
		}
		if (value === min) {
			minus.setAttribute('disabled', 'disabled')
		} else {
			minus.removeAttribute('disabled')
		}
	}
	document.addEventListener('click', evt => {
		let event = new Event('change')
		if (evt.target.closest('.counter__btn_minus')) {
			let input = evt.target.closest('.counter').querySelector('input')
			input.stepDown()
			input.dispatchEvent(event)
			btnDisabled(input)
		}
		if (evt.target.closest('.counter__btn_plus')) {
			let input = evt.target.closest('.counter').querySelector('input')
			input.stepUp()
			input.dispatchEvent(event)
			btnDisabled(input)
		}
	})
}
counter()
