import Dropzone from 'dropzone'
if (document.getElementById('my-dropzone')) {
	let myDropzone = new Dropzone('#my-dropzone', {
		maxFile: 5,
		maxFilesize: 128,
		clickable: '#dropzone',
		addRemoveLinks: true,
		previewsContainer: '.previews-container',
		dictResponseError: 'Server not Configured',
		acceptedFiles: '.jpg,.jpeg,.png,.gif,.psd,.pdf,.ai,.cdr,.indd,.zip,.7z,.rar,.tar,.gz'
	})
}
const arrRadioDelivery = document.querySelectorAll('[name="delivery"]')
const showActive = () => {
	arrRadioDelivery.forEach(radio => {
		let tab = document.getElementById(radio.dataset.tab)
		if (tab && radio.checked) {
			tab.style.display = 'block'
		} else if (tab) {
			tab.style.display = 'none'
		}
	})
}
arrRadioDelivery.forEach(radio => {
	showActive()
	radio.addEventListener('change', () => {
		showActive()
	})
})
